/*=======  HEADER CSS  =======*/
header {
	&.header-absolute {
		position: absolute;
		left: 0;
		width: 100%;
		z-index: 99;
		background-color: transparent;
		top: 35px;
	}

	.container.container-custom-one {
		@media #{$xl} {
			max-width: 100%;
		}
		@media #{$lg} {
			max-width: 100%;
		}
	}

	.nav-container {
		.toggle {
			margin-right: 50px;
			@media #{$lg} {
				margin-right: 20px;
			}
			@media #{$lg} {
				margin-right: 15px;
			}
			@media #{$md} {
				display: none;
			}
			@media #{$sm} {
				display: none;
			}
			a {
				color: $heading-color;
				font-size: 24px;
				padding: 10px;
			}
		}

		.site-logo {
			img {
				max-width: 250px;
				@media #{$lg} {
					max-width: 200px;
				}
				@media #{$xsm} {
					max-width: 180px;
				}
			}
			.sticky-logo {
				display: none;
			}
		}

		.header-info {
			@media #{$md} {
				padding: 40px 20px 0;
			}

			@media #{$sm} {
				padding: 40px 20px 0;
			}

			.item {
				padding-left: 50px;
				position: relative;
				@media #{$md} {
					color: $white;
				}
				@media #{$sm} {
					color: $white;
				}
				i {
					position: absolute;
					left: 0;
					top: 6px;
					height: 30px;
					width: 30px;
					text-align: center;
					line-height: 30px;
					border-radius: 4px;
					background: $black;
					color: $white;
					font-size: 14px;
				}
				span {
					font-weight: 600;
					font-size: 14px;
					padding-bottom: 4px;
				}
				a {
					display: block;
				}
				.title {
					font-size: 24px;
					font-weight: 400;
					@media #{$xl} {
						font-size: 20px;
					}
					@media #{$lg} {
						font-size: 16px;
					}
					@media #{$md} {
						color: $white;
						font-size: 22px;
					}
					@media #{$sm} {
						color: $white;
						font-size: 22px;
					}
				}
				&:not(:last-child) {
					margin-right: 45px;
					@media #{$xl} {
						margin-right: 15px;
					}
					@media #{$lg} {
						margin-right: 15px;
					}
					@media #{$md} {
						margin-right: 0;
						margin-bottom: 30px;
					}
					@media #{$sm} {
						margin-right: 0;
						margin-bottom: 30px;
					}
				}
			}
		}

		.menu-items {
			ul li {
				display: inline-block;
				position: relative;
				a {
					font-size: 16px;
					font-weight: 600;
					color: $black;
					text-transform: capitalize;
					line-height: 80px;
					padding: 0px 20px;
					@media #{$xl} {
						font-size: 14px;
						padding: 0 10px;
					}
					@media #{$lg} {
						padding: 0 8px;
					}
				}

				.submenu {
					position: absolute;
					left: 0;
					top: 100%;
					width: 200px;
					background-color: #fff;
					opacity: 0;
					visibility: hidden;
					transition: all 0.3s ease-out 0s;
					z-index: 99;
					height: auto;

					li {
						display: block;
						a {
							display: block;
							padding: 8px 24px;
							position: relative;
							transition: all 0.3s ease-out 0s;
							border-radius: 0;
							margin: 0 0;
							line-height: 30px !important;
							border-bottom: 1px solid rgba(242, 244, 248, 0.7);
							color: $text-color;

							&:hover {
								background-color: $primary-color;
								color: $white !important;
								border-color: $primary-color;
							}
						}

						.submenu {
							left: 100%;
							top: 0%;
						}

						&:hover {
							.submenu {
								top: 0;
							}
						}
					}
				}

				&:hover {
					& > .submenu {
						opacity: 1;
						visibility: visible;
						top: 100%;
					}
				}

				.dd-trigger {
					display: none;
				}
			}
		}

		.nav-pushed-item {
			display: none;
		}

		.navbar-toggler {
			border: none;
			background-color: transparent;
			cursor: pointer;
			display: none;

			span {
				position: relative;
				background-color: $heading-color;
				border-radius: 3px;
				display: block;
				height: 3px;
				margin-top: 5px;
				padding: 0;
				transition-duration: 300ms;
				width: 30px;
				cursor: pointer;
				display: block;
			}
			&.active {
				span:nth-of-type(1) {
					transform: rotate3d(0, 0, 1, 45deg);
					top: 8px;
				}
				span:nth-of-type(2) {
					opacity: 0;
				}
				span:nth-of-type(3) {
					transform: rotate3d(0, 0, 1, -45deg);
					top: -8px;
				}
			}
		}

		.navbar-close {
			position: absolute;
			top: 20px;
			right: 20px;
			z-index: 12;
			display: none;
			.cross-wrap {
				width: 26px;
				height: 26px;
				cursor: pointer;
				position: relative;
				span {
					position: absolute;
					display: block;
					width: 100%;
					height: 2px;
					border-radius: 6px;
					background: $white;
					&.top {
						top: 12px;
						left: 0;
						-webkit-transform: rotate(45deg);
						transform: rotate(45deg);
					}
					&.bottom {
						bottom: 12px;
						left: 0;
						-webkit-transform: rotate(-45deg);
						transform: rotate(-45deg);
					}
				}
			}
		}

		.nav-menu {
			align-items: baseline !important;
			.menu-items {
				ul li {
					a  {
						padding-top: 20px;
						padding-bottom: 20px;
					}
				}
			}
		}

		&.breakpoint-on {
			.nav-menu {
				background-color: $primary-color;
				position: fixed;
				top: 0;
				left: -300px;
				z-index: 9999;
				width: 300px;
				height: 100%;
				transition-duration: 500ms;
				padding: 0;
				box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
				display: block;
				overflow-x: hidden;
				overflow-y: scroll;
				padding-top: 70px;
				.menu-items {
					ul li {
						display: block;
						a {
							display: block;
							border-bottom: 1px solid rgba(255, 255, 255, 0.5);
							color: $white !important;
							line-height: 45px !important;
						}
						.submenu {
							width: 100%;
							position: relative;
							top: 0;
							left: 0;
							box-shadow: none;
							background-color: transparent;
							visibility: visible;
							opacity: 1;
							display: none;
							transition: none;
							li a {
								color: $white;
								padding: 0px 20px 0 40px;
								line-height: 45px !important;
								&:hover {
									border-color: rgba(255, 255, 255, 0.5);
								}
							}
						}

						.dd-trigger {
							display: block;
							position: absolute;
							right: 0;
							height: 45px;
							width: 45px;
							top: 0;
							border-left: 1px solid rgba(255, 255, 255, 0.5);
							z-index: 2;
							background: transparent;
							text-align: center;
							line-height: 45px;
							cursor: pointer;
							color: $white;
							font-size: 20px;
						}
					}
				}

				&.menu-on {
					left: 0;
				}
			}

			.nav-pushed-item,
			.navbar-close,
			.navbar-toggler {
				display: block;
			}
		}
	}

	&.header-two {
		.nav-container {
			.toggle a,
			.menu-items ul li a {
				color: $white;
			}

			.navbar-toggler {
				span {
					background-color: $white;
				}
			}
		}
		.header-info {
			.item {
				i {
					background: $primary-color;
					@media #{$md} {
						background: $black;
					}
					@media #{$sm} {
						color: $black;
					}
				}
				a,
				&,
				.title {
					color: $white;
				}
			}
		}
	}

	&.header-three {
		background-color: transparent;
		position: relative;

		.main-menu-area {
			position: absolute;
			left: 0;
			width: 100%;
			z-index: 99;
			height: auto;
			bottom: -40px !important;
			@media #{$tiny} {
				position: relative;
				bottom: 0;
				top: 0;
			}
			&.sticky-header.sticky-active {
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: auto;
				z-index: 999;
				animation: sticky 1.2s;
				.nav-container {
					box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
					.menu-items ul li a {
						line-height: 100px;
					}
				}
			}
		}

		.header-top {
			padding-top: 5px;
			padding-bottom: 75px;
			font-family: $roboto;
			background-color: $heading-color;
			@media #{$md} {
				padding-bottom: 60px;
			}
			@media #{$sm} {
				text-align: center;
				padding-bottom: 50px;
			}
			@media #{$tiny} {
				display: none;
			}
			&,
			a {
				color: $white;
			}
			ul.header-top-info {
				@media #{$sm} {
					margin-top: 10px;
				}
				li {
					display: inline-block;
					margin-left: 15px;
					@media #{$xsm} {
						margin-left: 5px;
					}
					i {
						margin-right: 10px;
						color: $primary-color;
					}
				}
			}
		}

		.nav-container {
			height: 80px;

			background-color: $white;
			padding: 0 40px;
			@media #{$lg} {
				padding: 0 10px;
			}
			@media #{$md} {
				padding: 10px;
			}
			@media #{$sm} {
				padding: 10px;
			}

			@media #{$tiny} {
				padding: 10px 5px;
				margin-left: -15px;
				margin-right: -15px;
			}

			.site-logo {
				img {
					@media #{$lsm} {
						max-width: 200px;
					}
					@media #{$tiny} {
						max-width: 150px;
					}
				}
			}

			.menu-items {
				ul li {
					a {
						line-height: 100px !important;
					}
					.submenu {
						li {
							a {
								line-height: 30px;
							}
						}
					}
				}

				// ul li:last-child{
				// 	background-color: $secondary-color !important;
				// 	a {
				// 		color: $white;
				// 		font-weight: 700 !important;
				// 	}
				// }
			}

			.menu-right-buttons {
				display: flex;
				.toggle {
					margin-right: 0;
				}

				& > div {
					margin-left: 20px;
					@media #{$lg} {
						margin-left: 5px;
					}
					@media #{$sm} {
						margin-left: 5px;
					}
				}

				.navbar-toggler {
					padding: 15px;
					@media #{$xsm} {
						padding: 5px 10px;
						background-color: transparent !important;
						border: none !important;
						span {
							width: 20px;
						}
					}
				}

				a {
					width: 65px;
					height: 65px;
					border: 2px solid rgba($color: #858585, $alpha: 0.2);
					text-align: center;
					line-height: 65px;
					padding: 0;
					color: $black;
					@media #{$xsm} {
						height: 40px;
						width: 40px;
						line-height: 40px;
						font-size: 14px;
					}
					&:hover {
						background-color: $primary-color;
						color: $white;
					}
				}

				.special-a {
					width: 65px;
					height: 65px;
					border: none !important;
					text-align: center;
					line-height: 65px;
					padding: 0;
					color: $black;
					@media #{$xsm} {
						height: 40px;
						width: 40px;
						line-height: 40px;
						font-size: 14px;
					}
					&:hover {
						background-color: transparent;
					}
				}
			}
		}

		.search {
			position: relative;
			.search-icon {
				position: relative;
				display: block;
				.close-icon,
				.open-icon {
					position: absolute;
					left: 50%;
					top: 50%;
					z-index: 2;
					transform: translate(-50%, -50%);
				}
				.close-icon {
					visibility: hidden;
					opacity: 0;
				}
				&.active {
					.close-icon {
						visibility: visible;
						opacity: 1;
					}
					.open-icon {
						visibility: hidden;
						opacity: 0;
					}
				}
			}
			.search-form {
				position: absolute;
				right: 0;
				width: 350px;
				display: none;
				top: calc(100% + 27px);
				@media #{$md} {
					top: calc(100% + 17px);
				}
				@media #{$lsm} {
					top: calc(100% + 10px);
				}
				@media #{$xsm} {
					top: calc(100% + 20px);
					width: 250px;
				}
				@media #{$tiny} {
					right: -100%;
					top: calc(100% + 12px);
				}
				form {
					position: relative;
					input {
						width: 100%;
						background-color: $main-bg;
						height: 60px;
						border: none;
						padding-left: 30px;
						padding-right: 60px;
						font-size: 14px;
						@media #{$xsm} {
							height: 50px;
						}
					}
					button {
						border: none;
						position: absolute;
						right: 0;
						top: 0;
						width: 60px;
						height: 100%;
						z-index: 1;
						background-color: $primary-color;
						font-size: 14px;
						color: $white;
						@include transition(0.3s);
						&:hover {
							background-color: $black;
							color: $white;
						}
					}
				}
			}
		}
	}

	&.inner-page.header-absolute {
		background-color: $white;
		top: 0;
		.nav-container {
			@media #{$md} {
				padding: 10px 0;
			}
			@media #{$sm} {
				padding: 10px 0;
			}
			.menu-items ul li a {
				line-height: 130px;
			}
		}
	}

	&.sticky-header.sticky-active {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		background-color: $white;
		box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
		z-index: 999;
		animation: sticky 1.2s;

		.nav-container {
			@media #{$md} {
				padding: 10px 0;
				.site-logo img {
					max-width: 200px;
				}
			}

			@media #{$sm} {
				padding: 10px 0;
			}

			.menu-items ul li {
				a {
					line-height: 100px;
				}
			}

			.site-logo {
				.main-logo {
					display: none;
				}
				.sticky-logo {
					display: block;
				}
			}
		}
		&.header-two {
			.nav-container {
				.toggle a,
				.menu-items ul li a {
					color: $black;
				}
				.navbar-toggler {
					span {
						background-color: $black;
					}
				}
			}
			.header-info {
				.item {
					a,
					&,
					.title {
						color: $black;
						@media #{$md} {
							color: $white;
						}
						@media #{$sm} {
							color: $white;
						}
					}
				}
			}
		}
	}
}

/*=======  Off canvas menu  =======*/
.offcanvas-wrapper {
	position: fixed;
	width: 450px;
	top: 0;
	min-height: 100vh;
	left: 0;
	transition: all 0.4s;
	z-index: 999;
	opacity: 0;
	visibility: hidden;
	margin-left: -450px;

	&.show-offcanvas {
		margin-left: 0;
		opacity: 1;
		visibility: visible;
	}

	.offcanvas-overly {
		position: fixed;
		height: 100vh;
		width: 100%;
		z-index: 98;
		background-color: #1e1e1e;
		left: 0;
		top: 0;
		transition: all 0.4s;
		cursor: pointer;
		opacity: 0;
		visibility: hidden;

		&.show-overly {
			visibility: visible;
			opacity: 0.5;
		}
	}
	.offcanvas-widget {
		background-color: $white;
		width: 100%;
		height: 100vh;
		padding: 70px 40px;
		position: relative;
		z-index: 99;

		a.offcanvas-close {
			position: absolute;
			right: 30px;
			top: 30px;
			font-size: 28px;
			color: $primary-color;
		}

		.widget {
			margin-bottom: 30px;
			font-size: 14px;
			.widget-title {
				font-size: 20px;
				margin-bottom: 25px;
				line-height: 1;
				position: relative;
				padding-left: 30px;
				&::before {
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 2px;
					background-color: $primary-color;
					content: "";
				}
			}
			/* Search Widget */
			&.search-widget {
				form {
					position: relative;
					input {
						width: 100%;
						background-color: $main-bg;
						height: 60px;
						border: none;
						padding-left: 30px;
						padding-right: 60px;
						font-size: 14px;
					}
					button {
						border: none;
						position: absolute;
						right: 0;
						top: 0;
						width: 60px;
						height: 100%;
						z-index: 1;
						background-color: $primary-color;
						font-size: 14px;
						color: $white;
						@include transition(0.3s);
						&:hover {
							background-color: $black;
							color: $white;
						}
					}
				}
			}

			/* Nav Widget */
			&.nav-widget {
				ul li {
					line-height: 30px;
					a {
						color: $text-color;
						font-weight: 700;
						&:hover {
							padding-left: 10px;
							color: $primary-color;
						}
					}
					.submenu {
						padding-left: 10px;
					}
				}
			}

			/* Social Link */
			&.social-link {
				ul li {
					display: inline-block;
					margin-right: 5px;
					a {
						height: 40px;
						width: 40px;
						background-color: $primary-color;
						color: $white;
						line-height: 40px;
						text-align: center;
						&:hover {
							background-color: $heading-color;
						}
					}
				}
			}
		}
	}
}

/*=======  Breadcrumb CSS  =======*/
.breadcrumb-area {
	background-size: cover;
	background-position: center;
	position: relative;
	z-index: 1;
	margin-top: 130px;

	@media #{$md} {
		margin-top: 100px;
	}

	@media #{$lsm} {
		margin-top: 100px;
	}

	@media #{$xsm} {
		margin-top: 80px;
	}

	&::before {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: -1;
		background-color: $heading-color;
		content: "";
		opacity: 0.7;
	}

	.breadcrumb-text {
		position: relative;
		padding: 100px 0;
		&,
		h2.page-title {
			color: $white;
		}
		h2.page-title {
			font-size: 72px;
			letter-spacing: -1px;
			@media #{$lsm} {
				font-size: 60px;
			}
			@media #{$xsm} {
				font-size: 50px;
			}
		}
		span {
			font-size: 14px;
			font-weight: 700;
			letter-spacing: 4px;
			text-transform: uppercase;
			padding-bottom: 25px;
			@media #{$sm} {
				letter-spacing: 2px;
			}
			@media #{$xsm} {
				font-size: 12px;
			}
		}

		.breadcrumb-nav {
			position: absolute;
			right: 0;
			bottom: 0;
			background-color: $white;
			padding: 20px 40px;
			z-index: 2;
			@media #{$xsm} {
				padding: 15px 20px;
				font-size: 14px;
			}
			li {
				display: inline-block;
				font-weight: 700;
				position: relative;
				&,
				a {
					color: $heading-color;
				}

				a:hover {
					color: $primary-color;
				}

				&:not(:first-child) {
					padding-left: 10px;
					margin-left: 10px;
					&::before {
						position: absolute;
						left: -2px;
						top: 50%;
						transform: translateY(-48%);
						content: "|";
						color: $heading-color;
					}
				}

				&.active {
					color: $primary-color;
					&::before {
						color: $primary-color;
					}
				}
			}
		}
	}
}

/*=======  Breadcrumb CSS  =======*/
.breadcrumb-area-soft-shade {
	background-size: cover;
	background-position: center;
	position: relative;
	z-index: 1;
	margin-top: 130px;

	@media #{$md} {
		margin-top: 100px;
	}

	@media #{$lsm} {
		margin-top: 100px;
	}

	@media #{$xsm} {
		margin-top: 80px;
	}

	&::before {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		z-index: -1;
		background-color: $heading-color;
		content: "";
		opacity: 0.5;
	}

	.breadcrumb-text {
		position: relative;
		padding: 100px 0;
		&,
		h2.page-title {
			color: $white;
		}
		h2.page-title {
			font-size: 72px;
			letter-spacing: -1px;
			@media #{$lsm} {
				font-size: 60px;
			}
			@media #{$xsm} {
				font-size: 50px;
			}
		}
		span {
			font-size: 14px;
			font-weight: 700;
			letter-spacing: 4px;
			text-transform: uppercase;
			padding-bottom: 25px;
			@media #{$sm} {
				letter-spacing: 2px;
			}
			@media #{$xsm} {
				font-size: 12px;
			}
		}

		.breadcrumb-nav {
			position: absolute;
			right: 0;
			bottom: 0;
			background-color: $white;
			padding: 20px 40px;
			z-index: 2;
			@media #{$xsm} {
				padding: 15px 20px;
				font-size: 14px;
			}
			li {
				display: inline-block;
				font-weight: 700;
				position: relative;
				&,
				a {
					color: $heading-color;
				}

				a:hover {
					color: $primary-color;
				}

				&:not(:first-child) {
					padding-left: 10px;
					margin-left: 10px;
					&::before {
						position: absolute;
						left: -2px;
						top: 50%;
						transform: translateY(-48%);
						content: "|";
						color: $heading-color;
					}
				}

				&.active {
					color: $primary-color;
					&::before {
						color: $primary-color;
					}
				}
			}
		}
	}
}

.mobile-hide {
	@media #{$lsm} {
		display: none !important;
	}
	@media #{$xsm} {
		display: none !important;
	}
	@media #{$tiny} {
		display: none !important;
	}
}

.pt-115 {
	@media #{$lsm} {
		padding-top: 80px !important;
	}
	@media #{$xsm} {
		padding-top: 80px !important;
	}
	@media #{$tiny} {
		padding-top: 80px !important;
	}
}

.pb-115 {
	@media #{$lsm} {
		padding-bottom: 80px !important;
	}
	@media #{$xsm} {
		padding-bottom: 80px !important;
	}
	@media #{$tiny} {
		padding-bottom: 80px !important;
	}
}

footer .footer-widget-area .widget.nav-widget ul li:last-child {
	@media #{$xsm} {
		margin-bottom: 15px !important;
	}
}

.mobile-logo {
	@media #{$xsm} {
		img {
			height: 60px !important;
		}
	}
}

.pr-80 {
	@media #{$xsm} {
		padding-right: 0px !important;
	}
}
.pl-80 {
	@media #{$xsm} {
		padding-left: 0px !important;
	}
}

.no-pad-top-mobile {
	@media #{$xsm} {
		padding-top: 0px !important;
	}
}

.no-pad-bot-mobile {
	@media #{$xsm} {
		padding-bottom: 0px !important;
	}
}

.pb-115-special {
	padding-bottom: 10px !important;
}

.pb-115-with-img {
	@media #{$lsm} {
		padding-bottom: 120px !important;
	}
	@media #{$xsm} {
		padding-bottom: 120px !important;
	}
	@media #{$tiny} {
		padding-bottom: 120px !important;
	}
}

.mb-50 {
	@media #{$xsm} {
		margin-bottom: 20px !important;
	}
}

.contact-part .contact-form {
	@media #{$xsm} {
		padding: 30px !important;
	}
}

.contact-part .contact-info .info-box {
	@media #{$xsm} {
		padding: 10px 20px !important;
	}
}
.contact-part .contact-info .info-box .icon {
	@media #{$xsm} {
		margin-bottom: 0px !important;
	}
}
